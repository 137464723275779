import React, { useEffect } from 'react';
import './Brand.less';


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import logoImg from "../../assets/common/logo.png";
import infoImg_1 from '../../assets/brand/info_1.jpg';
import infoImg_2 from '../../assets/brand/info_2.jpg';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};


const BrandPage: React.FC = () => {

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <div className="brandPage">
            <div className="brandPage_info">
                <img  src={infoImg_1} alt="" className="brandPage_info-img"/>
                 <div className="brandPage__infoContent">
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="brandPage__infoContent-tl">先锋潮流艺术珠宝</div>
                      <div className="brandPage__infoContent-en">ABOUT US</div>
                    </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="brandPage__infoContent-text">
                         <p>品牌名源于汉语拼音中<span>abcd</span>的发音</p>
                         <p>以银为主的贵金属作为载体</p>
                         <p>通过超凡的设计与独特的定位</p>
                         <p>为现代都市潮流人士打造</p> 
                          <p>生活、社交、悦己的优选首饰品牌</p>
                         <p>融通艺术和潮流文化</p>
                         <p>为日常搭配提供出挑别样的珠宝首饰</p>
                         <p>传递新世代青年</p> 
                        <p>不被定义、多元自我的时尚态度</p> 
                     </div>
                   </motion.div> 
                </div>
            </div>
             <div className="brandPage_info" style={{marginTop: -10}}>
                <img  src={infoImg_2} alt="" className="brandPage_info-img"/>
                   <div className="brandPage__footer">
                    <div className="brandPage__footerContent">
                         <div className="brandPage__footerContent-text">
                             abcd啊啵呲嘚诞生于2023年6月，秉持着“引领新世代银饰浪潮”的愿景，创建了这个品牌品牌主张回归首饰的纯粹本质，融通艺术和潮流等文化元素，以独特设计塑造让人惊叹的银饰作品，迎接新世代的银饰浪潮。
                         </div>
                         <div className="brandPage__footerContent-text">
                             2023年11月，郑州正弘城店作为品牌首店正式开业，随后在杭州的银泰和万达，以及深圳的星河、卓悦汇、KALEDO、大仟里等商场陆续开设品牌门店。
                         </div>
                         <div className="brandPage__footerContent-text">
                             打破传统的品牌运营模式，abcd啊啵呲嘚着眼全球，创建了经验丰富的海外团队，拥有成熟的海外运营体系。国际物流服务覆盖全球各大洲和主要港口，并与国际知名支付公司合作，旨在提供安全便捷的品牌服务。原创产品畅销海外28个国家。丰富多样的品牌支持和产品服务，深受各国友人的喜爱，让世界看见了中国珠宝。
                         </div>
                    </div>
                   <img  src={logoImg} alt="" className="brandPage__footer-img"/>
                </div>
             </div>
         
        </div>

    )
}

export default BrandPage;