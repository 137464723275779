import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Brand.less";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import infoImg_2 from '../../../../assets/home/info_2.png';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 60 }
};


const Brand: React.FC = () => {

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);
    return (
        <div className="homeBrand">
              <div className="homeBrand__info">
                <img  src={infoImg_2} alt="" className="homeBrand__info-img"/>
                <div className="homeBrand__infoText">
                 <motion.div
                      ref={ref}
                      variants={boxVariant}
                      initial="hidden"
                      animate={control}
                  >
                     <div className="homeBrand__infoText-tl">
                         先锋潮流艺术珠宝
                     </div> 
                     <div className="homeBrand__infoText-text">
                      <span>abcd</span>啊啵呲嘚诞生于<span>2023</span>年<span>6</span>月，秉持着“引领新世代银饰浪潮” 的愿景创建了这个品牌，
                         品牌主张回归首饰的纯粹本质，融通艺术和潮流等文化元素，以独特设计塑造让人惊叹的银饰作品，迎接新世代的银饰浪潮。
                     </div> 
                   </motion.div>
                    <motion.div
                      ref={ref}
                      variants={boxVariant1}
                      initial="hidden"
                      animate={control}
                  >
                      <Link to="/brand" className="homeBrand__infoMore">
                        <span className="homeBrand__infoMore-span">了解更多品牌故事</span>
                        <i className="homeBrand__infoMore-arrow"></i>
                    </Link>
                    </motion.div>
               </div>

            </div>
        </div>
    );
};

export default Brand;