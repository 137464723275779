import type { FC } from "react";
import type { RouteObject } from "react-router-dom";
import { lazy } from 'react';
import { Navigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import MainLayout  from '../pages/MainLayout';
import HomePage from "../pages/home/Home";
import BrandPage from "../pages/brand/Brand";
import ContactPage from "../pages/contact/Contact";
import ShopPage from "../pages/shop/Shop";
import SeriesYihuoPage from "../pages/seriesYihuo/SeriesYihuo";
import SeriesRelianPage from "../pages/seriesRelian/SeriesRelian";
import SeriesQianjiPage from "../pages/seriesQianji/SeriesQianji";
import SeriesAnyePage from "../pages/seriesAnye/SeriesAnye";
import SeriesLiehuangPage from "../pages/seriesLiehuang/SeriesLiehuang";


const RouteList: RouteObject[] = [
   {
    path: '/',
    element: <MainLayout />,
    children: [
    {
      path: '/home',
      element: <HomePage />,
    },
    {
      path: '/brand',
      element: <BrandPage />,
    },
    {
      path: '/contact',
      element: <ContactPage />,
     },
     {
      path: '/shop',
      element: <ShopPage />,
     },
      {
      path: '/seriesYihuo',
      element: <SeriesYihuoPage />,
     },
     {
      path: '/seriesRelian',
      element: <SeriesRelianPage />,
     },
    
     {
      path: '/seriesQianji',
      element: <SeriesQianjiPage />,
     },
     {
      path: '/seriesAnye',
      element: <SeriesAnyePage />,
     },
     {
      path: '/seriesLiehuang',
      element: <SeriesLiehuangPage />,
     },
     {
      path: '/',
      element: <HomePage />,
    },
   ]
 },
]

  
  const RenderRouter: FC = () => {
    const element = useRoutes(RouteList);
    return element;
  };
  
  export default RenderRouter;