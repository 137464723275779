import React, { useState, useRef, useEffect } from 'react';
import './SeriesAnye.less';
import { Products } from '../../components';
import { anyeItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import infoImg_1 from '../../assets/series/anye/info_1.png';
import infoImg_2 from '../../assets/series/anye/info_2.png';
import infoImg_4 from '../../assets/series/anye/info_4.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const SeriesAnyePage: React.FC = () => {
 
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <div className="seriesAnyePage">
            <div className="seriesAnyePage__info">
                <img  src={infoImg_1} alt="" className="seriesAnyePage__info-img"/>
                   <div className="seriesAnyePage__infoContent">
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesAnyePage__infoContent-tl">暗夜荆棘</div>
                   </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesAnyePage__infoContent-text">
                         <p>生于丛林深处</p>
                         <p>长于荒野之上</p>
                         <p>有着不羁与坚韧</p>
                         <p>还有柔韧与锋芒</p>
                         <div>&nbsp;</div>   
                         <p>藤蔓交织，灵魂苏醒</p> 
                         <p>突破桎梏，肆意生长 </p>  
                         <p>感受现代主义反叛美学</p> 
                         <p>打开荆棘世界</p> 
                         <div>&nbsp;</div>
                         <p>荆棘丛生，荆棘重生</p> 
                         <p>不愿屈服，野蛮生长</p>      
                     </div>
                     </motion.div> 
                </div>
            </div>
           <div className="seriesAnyePage__info" >
                <img  src={infoImg_2} alt="" className="seriesAnyePage__info-img"/>
                 <div className="seriesAnyePage__infoTwo">
                    <p>危险又迷人的荆棘</p>
                    <p>由荆与棘两组植物藤条交错缠绕生长而成</p>
                    <p>象征着顽强的生命力</p>
                     <p>&nbsp;</p>
                    <p>「暗夜荆棘」系列主张勇敢与自由</p>   
                    <p>诠释新生代肆意生长</p>
                    <p>知畏无畏的生活态度</p> 
                </div>
            </div>
           
            <div className="seriesAnyePage__product">
                   <div className="seriesAnyePage__productWrap">  
                         <Products 
                           data1={anyeItems[0]}
                           data2={anyeItems[1]}
                           nameColor="#d6d6d6"
                         />
                          <Products 
                           data1={anyeItems[2]}
                           data2={anyeItems[3]}
                           nameColor="#d6d6d6"
                         />
                          <Products 
                           data1={anyeItems[4]}
                           data2={anyeItems[5]}
                           nameColor="#d6d6d6"
                         />
                         <Products 
                           data1={anyeItems[6]}
                           data2={anyeItems[7]}
                           nameColor="#d6d6d6"
                         />
                         <Products 
                           data1={anyeItems[8]}
                           data2={anyeItems[9]}
                           nameColor="#d6d6d6"
                         />
                         <Products 
                           data1={anyeItems[10]}
                           data2={anyeItems[11]}
                           nameColor="#d6d6d6"
                         />
                         <Products 
                           data1={anyeItems[12]}
                           data2={anyeItems[13]}
                           nameColor="#d6d6d6"
                         />
                         <Products 
                           data1={anyeItems[14]}
                           data2={anyeItems[15]}
                           nameColor="#d6d6d6"
                         />
                   </div>
            </div>
             <div className="seriesAnyePage__info">
                <img  src={infoImg_4} alt="" className="seriesAnyePage__info-img"/>
             </div>
        </div>
    )
}

export default SeriesAnyePage;