import React, { useState, useEffect, useRef } from 'react';
import './SeriesRelian.less';

import {  Products } from '../../components'
import { relianItems } from '../Data';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import logoImg from "../../assets/common/logo.png";

import infoImg_1 from '../../assets/series/relian/info_1.png';
import infoImg_2 from '../../assets/series/relian/info_2.png';
import infoImg_3 from '../../assets/series/relian/info_3.png';

import pImg_1 from '../../assets/series/relian/p_1.jpg';
import pImg_2 from '../../assets/series/relian/p_2.jpg';
import barImg from '../../assets/series/relian/c.png';
import controlImg from '../../assets/series/relian/control.png';

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const SeriesRelianPage: React.FC = () => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <div className="seriesRelianPage">
            <div className="seriesRelianPage__info">
                <img  src={infoImg_1} alt="" className="seriesRelianPage__info-img"/>
            </div>
            <div className="seriesRelianPage__info">
                <img  src={infoImg_2} alt="" className="seriesRelianPage__info-img" style={{marginTop: "-10px"}}/>
                <div className="seriesRelianPage__infoContent">
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesRelianPage__infoContent-tl">热链玩家</div>
                    </motion.div>
                   <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                   >
                     <div className="seriesRelianPage__infoContent-text">
                         <p>链条自带潮流基因——简约率性 硬朗酷飒</p>
                         <p>是时髦精们不可以或缺的元素</p>
                         <p>百变链条不受长度约束</p>
                         <p>可与不同款式的链条和吊坠自由搭配</p>   
                     </div>
                      <div className="seriesRelianPage__infoContent-text">
                         <p>每一条链  都值得去探索</p>
                         <p>即刻从链出发  探索个性饰界</p>
                     </div>
                    </motion.div>
                </div>
                 
            </div>
           <div className="seriesRelianPage__info" style={{marginTop: "-10px"}}>
                <img  src={infoImg_3} alt="" className="seriesRelianPage__info-img"/>
                 <div className="seriesYihuoPage__infoThree">
                    <p>做旧工艺制作搭配经典链条元素</p>
                    <p>形成重色与亮银的鲜明对比</p>   
                    <p>专利链口设计 一链多戴玩法多样 探索无限搭配可能</p>   
                    <p>打造“百变链条”的DIY乐趣</p>
                    <p>随性达人 以链启始</p>      
                </div>
            </div>
            <div className="seriesRelianPage__foot">
                <div className="seriesRelianPage__product">
                    <div className="seriesRelianPage__productWrap" >
                      <Products 
                           data1={relianItems[0]}
                           data2={relianItems[1]}
                           nameColor="#151515"
                         />
                          <Products 
                           data1={relianItems[2]}
                           data2={relianItems[3]}
                           nameColor="#151515"
                         />
                          <Products 
                           data1={relianItems[4]}
                           data2={relianItems[5]}
                           nameColor="#151515"
                         />
                         <Products 
                           data1={relianItems[6]}
                           data2={relianItems[7]}
                           nameColor="#151515"
                         />
     
                    </div>
                     

                </div>
                <div className="seriesRelianPage__footer">
                <img  src={logoImg} alt="" className="seriesRelianPage__footer-img"/>
                </div>
            </div>
        </div>
    )
}

export default SeriesRelianPage;