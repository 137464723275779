import React, { useEffect } from 'react';
import './Products.less';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface ProductsProps {
    data1: { name: string, pic: string, bigPic: string };
    data2: { name: string, pic: string, bigPic: string };
    nameColor?: string;
}

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 0.5 } },
    hidden: { opacity: 0, translateY: 100 }
};


export const Products: React.FC < ProductsProps > = (props) => {

    const { data1, data2, nameColor } = props;

   const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                   >
        <div className="productList" >
             <div className="productItem">
                 <img  
                   src={data1.pic} 
                   alt="" 
                   className="productItem-img"
                  />
                 <div className="productItem-name" style={{ color: `${nameColor}` }} >[{data1.name}]</div>
              </div>
              <div className="productItem">
                 <img  
                   src={data2.pic} 
                   alt="" 
                   className="productItem-img"
                  />
                 <div className="productItem-name" style={{ color: `${nameColor}` }}>[{data2.name}]</div>
              </div>
          </div>
           </motion.div>
    )
}