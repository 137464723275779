import React, { useState, useEffect } from "react";
import './Header.less';
import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assets/common/logo.png";
import iconMenu from "../../assets/common/icon_menu.png";
import logoWhiteImg from "../../assets/common/logo_white.png";
import iconMenuWhite from "../../assets/common/icon_menu_white.png";
import iconClose from '../../assets/common/icon_close.png';

export const Header: React.FC = () => {
    // 路由相关
    const location = useLocation();
    useEffect(() => {
        // 在组件加载时以及每次路由变化时执行的逻辑
        const hash = window.location.hash;
        if (!hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const [isShowOver, setIsShowOver] = useState(false);
    const handleClickMenu = () => {
        setIsShowOver(!isShowOver);
    };

    
   const [headerIn, setHeaderIn] = useState(false);
   useEffect(() => {
    const handleScroll = () => {
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (scrollTop > 100) {
         setHeaderIn(true)
      } else {
         setHeaderIn(false)
      }
    };

     window.addEventListener('scroll', handleScroll);

     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
    }, []); 

    return (
      <>
      {
        headerIn ? <div className="headerContainer in">
           <div className="header__row">
               <a className="header__menu" onClick={handleClickMenu} >
                  <img  src={iconMenuWhite} alt="" className="header__menu-img"/>
               </a>
               <Link to="/" className="header__logo">
                  <img  src={logoWhiteImg} alt="" className="header__logo-img"/>
               </Link>
               <div className="header__search">&nbsp;</div>
           </div>
        </div> :
        <div className="headerContainer">
           <div className="header__row">
               <a className="header__menu" onClick={handleClickMenu} >
                  <img  src={iconMenu} alt="" className="header__menu-img"/>
               </a>
               <Link to="/" className="header__logo">
                  <img  src={logoImg} alt="" className="header__logo-img"/>
               </Link>
               <div className="header__search">&nbsp;</div>
           </div>
        </div>
      }
         
         {
          isShowOver && 
           <div className="headerOverplay">
               <div className="headerContainer__row clearfix" >
                <div className="headerContainer__menuIcon" onClick={handleClickMenu}>
                     <img src={iconClose} alt="" className="headerContainer__menu-image" />
                </div>
                <div className="headerContainer__name">
                    啊啵呲嘚
                </div>
            </div>
                <div  className="headerContainer__menu" onClick={handleClickMenu}>
                     <div className="headerContainer__menuCol" onClick={handleClickMenu} >
                        <Link to="/" className="headerContainer__menu-title" >
                             首页导航
                        </Link>
                        <div className="headerContainer__items">
                           <Link to="/" className="headerContainer__menu-itemLink" >
                              首页
                            </Link>
                            <Link to="/brand" className="headerContainer__menu-itemLink" >
                              品牌
                            </Link>
                            <Link to="/shop" className="headerContainer__menu-itemLink" >
                             门店
                            </Link>
                            <Link to="/contact" className="headerContainer__menu-itemLink" >
                             联系
                            </Link>
                        </div>
                    </div>
                     <div className="headerContainer__menuCol" onClick={handleClickMenu} >
                        <a className="headerContainer__menu-title" >
                           系列导航
                        </a>
                        <div className="headerContainer__items">
                            <Link to="/seriesYihuo" className="headerContainer__menu-itemLink" >
                             彝火生花
                            </Link>
                           <Link to="/seriesAnye" className="headerContainer__menu-itemLink" >
                             暗夜荆棘
                            </Link>
                             <Link to="/seriesLiehuang" className="headerContainer__menu-itemLink" >
                             猎荒派对
                            </Link>
                             <Link to="/seriesRelian" className="headerContainer__menu-itemLink" >
                             热链玩家
                            </Link>
                             <Link to="/seriesQianji" className="headerContainer__menu-itemLink" >
                             千机蝶变
                            </Link>
                        </div>
                    </div>
                </div>
        </div>
        }
      </>
     
    )
}